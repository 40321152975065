<template>
  <div class="v-step-list-contract grid-dashboard">
    <transition name="fade" appear>
        <div class="v-step-list-contract-product grid-item grid-dashboard grid-column-2">
            <div class="v-step-list-contract-item grid-item area accent">
                <div class="v-step-list-contract-title">
                <p>Условия индивидуального пенсионного плана</p>
                </div>
                <div class="v-step-list-contract-list">
                <ul>
                    <li>
                    <span class="v-step-list-contract-list-icon">
                        <svg>
                        <use xlink:href="@/assets/img/sprite.svg#product-icon1"></use>
                        </svg>
                    </span>
                    <p class="v-step-list-contract-list__title">Первоначальный взнос от 1 000 руб</p>
                    </li>
                    <li>
                    <span class="v-step-list-contract-list-icon">
                        <svg>
                        <use xlink:href="@/assets/img/sprite.svg#product-icon2"></use>
                        </svg>
                    </span>
                    <p class="v-step-list-contract-list__title">Текущие взносы — от 500 рублей с любой периодичностью</p>
                    </li>
                    <li>
                    <span class="v-step-list-contract-list-icon">
                        <svg>
                        <use xlink:href="@/assets/img/sprite.svg#product-icon3"></use>
                        </svg>
                    </span>
                    <p class="v-step-list-contract-list__title">Условия выплаты пенсии вы выбираете сами — срок выплаты от 3-х до 15-ти лет, выплата ежемесячно</p>
                    </li>
                    <li>
                    <span class="v-step-list-contract-list-icon">
                        <svg>
                        <use xlink:href="@/assets/img/sprite.svg#product-icon4"></use>
                        </svg>
                    </span>
                    <p class="v-step-list-contract-list__title">Выделенная инвестиционная стратегия с гарантией сохранности средств</p>
                    </li>
                </ul>
                </div>
            </div>
            <div class="v-step-list-contract-item grid-item area">
                <div class="v-step-list-contract-title">
                <p>Преимущества индивидуального пенсионного плана</p>
                </div>
                <div class="v-step-list-contract-list">
                <ul>
                    <li>
                    <p class="v-step-list-contract-list__title">Выплаты можно получать в 55(ж)/60(м) лет</p>
                    <p class="v-step-list-contract-list__desc">Сохраните пенсионный возраст</p>
                    </li>
                    <li>
                    <p class="v-step-list-contract-list__title">+13% каждый год</p>
                    <p class="v-step-list-contract-list__desc">Получите дополнительный доход с помощью социального налогового вычета, вернув 13% от суммы пенсионных взносов, не превышающих 120 000 руб.</p>
                    </li>
                    <li>
                    <p class="v-step-list-contract-list__title">Наследование</p>
                    <p class="v-step-list-contract-list__desc">Средства на индивидуальном пенсионном счете, а также полученный инвестиционный доход, наследуются в полном объеме в период накопления и период выплат</p>
                    </li>
                    <li>
                    <p class="v-step-list-contract-list__title">Пенсионные выплаты не облагаются НДФЛ</p>
                    <p class="v-step-list-contract-list__desc">Вне зависимости от суммы на индивидуальном пенсионном счете</p>
                    </li>
                </ul>
                </div>
            </div>
            <div class="v-step-list-contract-bottom grid-item area">
                <div class="v-step-list-contract-bottom-title">
                <p>Оформление займет не более 10 минут. Для оформления понадобятся паспорт, СНИЛС</p>
                </div>
                <div class="v-step-list-contract-bottom-btn">
                <button 
                    class="btn-big btn-text primary"
                    @click.prevent="beginContract"
                        >
                    Оформить продукт
                </button>
                </div>
            </div>
        </div>   
    </transition>
    
  </div>
</template>

<script>


import { mapState } from 'vuex'

export default {

  name: 'product_choose',

  props: {
    handleSubmit: Function,
    clearProjectSms: Function

  },


  computed: {

    ...mapState({

      productData: state => state.getProducts.data

    }),

  },


  data(){

    return {

      // номер продукта. В зависимости от него подставляется остальное
      product_type_id: 15,

      pens_pay_duration: null,
      vznos_type: null,

      pens_pay_period: "0",  // можно поставит 1 2 или 3 если в нетворке будет требовать при финализации
      scheme: 4,

    }

  },

  async created(){
    await this.clearProjectSms()
  },


  methods: {

    async beginContract(){


      await this.$store.dispatch('getProducts/get', {})

      this.pens_pay_duration = this.productData[this.product_type_id].pens_pay_duration.min
      this.vznos_type        = this.productData[this.product_type_id].vznos_type


      const state = await this.handleSubmit({ 
                                data: {
                                  product_type_id:   this.product_type_id,
                                  pens_pay_duration: this.pens_pay_duration,
                                  vznos_type:        this.vznos_type,
                                  pens_pay_period:   this.pens_pay_period,
                                  scheme:            this.scheme

                                },
                                myselfRedirect: true
                              })
      if(state.path){

        this.$router.push({ name: 'contractId-1' })

      }

    }

  },
}
</script>
